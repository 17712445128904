/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import AboutImage from "../../assets/about.jpg";
import { motion, useAnimation } from "framer-motion";
import {
  opacityAnimation,
  staggerChildrenAnimation,
} from "../../utils/animations";
import { useInView } from "react-intersection-observer";
import useHandleAnimation from "../../hooks/useHandleAnimation";
import { useLocation } from 'react-router-dom';

const About = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const animate = useHandleAnimation(inView, controls);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return (
    <Layout>
      <motion.section
        variants={staggerChildrenAnimation}
        animate={controls}
        className="container my-8 flex flex-col space-y-8"
        ref={ref}
        initial="hidden"
      >
        <div>
          <motion.img
            className="w-full h-[40vh] object-cover object-center rounded-lg mb-4"
            alt="about-banner"
            src={AboutImage}
            variants={opacityAnimation}
          />
          <motion.h2 className="text-h2" variants={opacityAnimation}>
            Защо да изберете мен за вашето парти?
          </motion.h2>
          <motion.p variants={opacityAnimation} className="text-lg">
            Творчеството ми започна още като дете, когато организирах събития с приятелите си за бабите на село.
            Бях избирана за водеща в началното училище, а след това и в гимназията. Е, толкова ми харесваше, че често сама предлагаше да участвам.
            След това завърших театрален колеж "Любен Гройс" със специалност актьорство за драматичен театър в град София. Съвсем на шега, след завършването си, започнах работа с деца.
            А сега бих казала, че децата са най-голямата ми и чиста любов.
            <br />
            <br />
            Аз вярвам, че всеки рожденник заслужава да бъде специален.
            Държа на персоналното отношение към всеки клиент. Готова съм да отделя колкото е нужно време, за да разбера вашите желания и да ги реализирам.
            Знам, че всяко дете има свой любим герой. За това отделям достатъчно време, за да създам специалното тематично парти за всеки малчуган. Вярвам, че за да бъде успешно всяко мое парти, е нужно да изградя безопасна, забавна и образователна среда за децата.
          </motion.p>
        </div>
      </motion.section>
    </Layout>
  );
};

export default About;
