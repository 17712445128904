/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import mainImage from "../../assets/main.jpg";
import { ServicesData } from "../../data/services";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  staggerChildrenAnimation,
  translateYAnimation,
} from "../../utils/animations";
import useHandleAnimation from "../../hooks/useHandleAnimation";

const Introduction = () => {
  const aboutControls = useAnimation();
  const servicesControls = useAnimation();

  const [refAbout, inViewAbout] = useInView();
  const [refServices, inViewServices] = useInView();

  const animateAbout = useHandleAnimation(inViewAbout, aboutControls);
  const animateServices = useHandleAnimation(inViewServices, servicesControls);

  return (
    <section className="container my-16 flex flex-col space-y-16">
      <motion.div
        className="flex flex-col text-center space-y-4 items-center"
        variants={staggerChildrenAnimation}
        animate={aboutControls}
        initial="hidden"
      >
        <motion.h2
          className="text-h2 font-bold"
          ref={refAbout}
          variants={translateYAnimation}
        >
          МОЯТА <span className="text-primary">ЦЕЛ</span>
        </motion.h2>
        <motion.p className="text-md md:text-lg" variants={translateYAnimation}>
          Разбирайки, че всяко дете е уникално,стремя се да персонализирам всяко
          парти, за да отразя интересите и мечтите на рожденика. Приоритет не ми
          е само организацията, но се стремя да създавам моменти на радост.
          Моята цел е да даравам усмивки на детските лица и всеки рожденик да се
          чувства ценен и уважаван.
        </motion.p>

        <motion.img
          src={mainImage}
          alt="main"
          width={400}
          variants={translateYAnimation}
        />
      </motion.div>

      <motion.div className="flex flex-col space-y-4 text-center">
        <motion.h2
          className="text-h2 font-bold"
          variants={translateYAnimation}
          animate={servicesControls}
          initial="hidden"
        >
          КАКВО <span className="text-primary">ПРЕДЛАГАМ</span>

        </motion.h2>

        <motion.h2
          className="text-h3 font-bold"
          variants={translateYAnimation}
          animate={servicesControls}
          initial="hidden"
        >
          Организирам незабравими и тематични рождени дни и партита за деца с игри, анимации и много смях!
        </motion.h2>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-y-6"
          variants={staggerChildrenAnimation}
          animate={servicesControls}
          initial="hidden"
        >
          {ServicesData.map((data, i) => (
            <motion.div
              className="flex flex-col items-center justify-start space-y-2"
              variants={translateYAnimation}
              ref={refServices}
            >
              <img
                src={data.icon}
                width={70}
                height={70}
                alt={`услуга-${data.title}`}
              />
              <p className="text-lg font-semibold">{data.title}</p>
              <p className="text-sm md:text-p">{data.desc}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Introduction;
